<template>
    <div class="member-merchandise">
     <router-view></router-view>
    </div>
</template>

<script>
export default {
	name: 'MemberIndex'
}
</script>

<style lang="less" scoped>
.member-merchandise {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
</style>
